<template>
    <div class="authBox">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="authBox--breadcrumb" >
        <el-breadcrumb-item :to="{ path: '/home' }">
          <font-awesome-icon icon="home" style="margin-right:10px;"/>首頁
        </el-breadcrumb-item  >
            
        <el-breadcrumb-item>
          <font-awesome-icon icon="search-dollar" style="margin-right:10px;"/>認證與授權管理
        </el-breadcrumb-item>
      </el-breadcrumb>

      <div class="authBox--searchBox">
        <div class="itemWrap">
          <label>部門</label>
          <el-select v-model="userQuery.DeptName" placeholder="請選擇部門" no-data-text="目前無部門" >
            <el-option key="0" label="全部" value="all" ></el-option>              
            <el-option :label="item" :value="item" v-for="item in deptList" :key="item" ></el-option>
          </el-select>
        </div>
        <div class="itemWrap">
          <label>使用者</label>
          <el-input v-model.trim="userQuery.UserName" placeholder="請輸入使用者" clearable ></el-input>
        </div>
        <div class="itemWrap" style="margin-left:15px;">
          <el-button type="primary" @click="handleSearch" ><font-awesome-icon icon="search"  style="margin-right:5px;" />查詢</el-button>
        </div>
      </div>
      <!-- <div class="authBox--addUserBox">
        <el-button type="danger" @click="addUser" ><font-awesome-icon icon="plus"  style="margin-right:5px;" />新增使用者</el-button>
      </div> -->
      <div class="authBox--listBox">
        <el-table :data="tableData" ref="multipleTable" style="width: 100%"  empty-text="暫無數據">
        <!-- <el-table-column type="index" label="序號" width="60" ></el-table-column> -->
        <el-table-column label="使用者名稱" prop="UserName" min-width="20%"></el-table-column>
        <el-table-column label="部門" prop="DeptName" min-width="20%"></el-table-column>
        <el-table-column label="Api名稱" prop="AccessApi" >
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="150">
            <template slot-scope="scope">
            <div class="accountManage__listBox--Content--userFunc" >
                <el-tooltip effect="dark" content="刪除" placement="left" style="color:#000;text-shadow:2px 2px 2px grey">
                <el-button type="text" @click="deleteUser(scope.row)">
                    <i class="el-icon-delete"></i>
                </el-button>
                </el-tooltip>                
                <el-tooltip effect="dark" content="編輯" placement="right" style="color:#000;text-shadow:2px 2px 2px grey" >
                <el-button @click="editUser(scope.row)" style="background-color:transparent;border:0px;" ><font-awesome-icon icon="edit"  />
                </el-button>                 
                </el-tooltip>
            </div>
            </template>
        </el-table-column>
        </el-table>
        <Pagination @currentChange="pageChange" :pageSize="Page_Size" :propsCurrentPage="Page_Num" :totalLists="totalLists"> </Pagination>          
      </div>

    <!-- 新增使用者 -->
    <!-- <el-dialog :title="setAccountTitle" :visible.sync="setAccount" width="80%" center>
        <div style="display:flex; flex-direction:column; align-items:center;">
            <div class="popBox">
                <div class="popBox--popLeftBox">
                    <el-form :model="addUserQuery" :rules="rules_setAccount" ref="ruleForm_setAccount" label-width="150px">
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="使用者名稱" prop="UserName">
                                <el-input v-model="addUserQuery.UserName"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="使用者角色" prop="Role">
                                <el-select class="w-full" v-model="addUserQuery.Role" placeholder="請選擇角色">                                
                                    <el-option label="使用者" value="user" ></el-option>
                                </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row type="flex">
                            <el-col :span="12">
                                <el-form-item label="使用者單位" prop="DeptName">          
                                    <el-input v-model="addUserQuery.DeptName"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="使用者聯絡資訊" prop="Contact">
                                <el-input v-model="addUserQuery.Contact"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-divider style="margin: 10px 0px;" ></el-divider>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="使用者email" >
                                <el-input v-model="addUserQuery.Account" :disabled="setAccountTitle=='編輯'"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="使用者密碼" prop="Password">
                                <el-input v-model="addUserQuery.Password" show-password :disabled="setAccountTitle=='編輯'"></el-input>
                                </el-form-item>
                            </el-col>                        
                        </el-row>
                        <el-form-item label="備註" prop="memo">
                        <el-input v-model="addUserQuery.Memo" type="textarea" :autosize="{ minRows: 2, maxRows: 4}" :disabled="setAccountTitle=='編輯'"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="popBox--popRightBox">
                    <el-card :body-style="{ padding: '0px' }" >
                        <div class="popRightBox--footer" >
                            <span>請選擇授權使用的Api</span>            
                        </div>
                        <div class="popRightBox--content">
                            <el-tree ref="userTree" show-checkbox default-expand-all 
                            :data="treedata" node-key="id"
                            @node-click="handleNodeClick"  
                            accordion empty-text="目前無可用Api"></el-tree>                    
                        </div>
                    </el-card>       
                </div>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="addUserAccount()"><font-awesome-icon icon="save"  style="margin-right:5px;" />儲存</el-button>
                <el-button type="danger" @click="setAccount = false"><font-awesome-icon icon="times" style="margin-right:5px;" />取消</el-button>
            </span>
        </div>
    </el-dialog> -->

    <!-- 新增使用者 -->

    <el-dialog :title="setAccountTitle" :visible.sync="setAccount" width="60%" center>
        <div style="display:flex; flex-direction:column; align-items:center;">
            <div class="popBox">
                <div class="popBox--popRightBox">
                    <el-card :body-style="{ padding: '0px' }" >
                        <div class="popRightBox--footer" >
                            <span>請選擇授權使用的Api</span>            
                        </div>
                        <div class="popRightBox--content">
                          <el-checkbox-group v-model="checkList" >
                            <el-checkbox :label="item.Name" :value="item.EndPoint" v-for="item in allApis" :key="item.ID">
                              {{ item.Name }}
                            </el-checkbox>
                          </el-checkbox-group>                 
                        </div>
                    </el-card>                         
                           
                    <!-- <el-card :body-style="{ padding: '0px' }" >
                        <div class="popRightBox--content">
                            <el-tree ref="userTree" show-checkbox default-expand-all 
                            :data="treedata" node-key="id"
                            @node-click="handleNodeClick"  
                            accordion empty-text="目前無可用Api"></el-tree>                    
                        </div>
                    </el-card>        -->
                </div>                
            </div>
            <span slot="footer" class="dialog-footer">
                <!-- <el-button type="primary" @click="addUserAccount()"><font-awesome-icon icon="save"  style="margin-right:5px;" />儲存</el-button> -->
                <el-button type="primary" @click="editUseApi()"><font-awesome-icon icon="save"  style="margin-right:5px;" />儲存</el-button>
                
                <el-button type="danger" @click="setAccount = false"><font-awesome-icon icon="times" style="margin-right:5px;" />取消</el-button>
            </span>            
        </div>
    </el-dialog>



    </div>
</template>

<script>
import Breadcrumb from "../components/Breadcrumb.vue";
import Pagination from "../components/Pagination.vue";
export default {
  // name : 'account-management',
  components: {Breadcrumb,Pagination},
  data() {
    var checkAccount = (rule, value, callback) => {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
      if (! value) {
        return callback( new Error('請輸入電子郵件' ))
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback()
        } else {
          callback( new Error('請輸入正確的電子郵件格式' ))
        }
      }, 100 )
    };
    return {
      setAccount: false,
      setAccountTitle: "新增使用者",   
      userMode: "add",         
      tableData: [],
      totalLists: 0,
      checkList: [],
      allApis: [],
      Page_Num: 1,
      Page_Size: 10,      
      /* 變更密碼 */
      changePWD_func: false,
      changePWDID: "",
      ruleForm: {
        oldPWD: "",
        newPWD: "",
        checkPWD: "",
      },   
      deptList: [],
      treedata: [],
      /* 查詢帳號 */
      userQuery: {
        //UserID: this.$store.state.userInfo.UserID,
        //UniComNm: this.$store.state.userInfo.UniComNm,
        UserName: "",
        Role: "user",
        DeptName: "all",
        Page_Size: 10,
        Page_Num:1
      },
      addUserQuery: {
        UserName:"",
        Role:"user",
        DeptName:"",
        Contact:"",
        Account:"",
        Password:"",
        Memo:""
      },
      rules_setAccount: {
          UserName: [{ required: true, message: "此為必填欄位", trigger: "blur" }],
          DeptName: [{ required: true, message: "此為必填欄位", trigger: "blur" }],
          Contact: [{ required: true, message: "此為必填欄位", trigger: "blur" }],
          Account: [{ required: true, validator: checkAccount, trigger: "blur" }],
      },      
    };
  },
  computed: {
    
  },
  methods: {
    handleNodeClick() {

    },
    async getTreeData() {

      let req= {Page_Size:100, Page_Num:1};
      //console.log(req);
      this.allApis = []
      await this.$api.apimgrs.getApi(req).then((res)=>{
        this.allApis = res.data;
        // this.totalLists = res.total;
      });

      //console.log(this.allApis);


        
        let childs = [];
        this.allApis.forEach(x=>{
            childs.push({label: x.Name, id: x.ID, endpoint: x.EndPoint});
        });
        

        let node = { label: "Api列表", children : childs, id: 0  };
        // console.log("node",node);
        this.treedata.push( node );
        //console.log("treeData",this.treedata);

      //console.log("treeData", this.treedata);
    },      

    addUser() {
        this.addUserQuery.Account="";
        this.addUserQuery.Password="";
        this.userMode="add";
        this.setAccount= true;
    },

    async editUser(val) {
        this.setAccountTitle = "編輯使用者授權";
        this.userMode ="edit";
        this.addUserQuery = val;

        console.log(val);
        // 清空userTree 
          // let that2 = this;
          // setTimeout(function () {              
          //   that2.$refs.userTree.setCheckedKeys([]);
          // }, (100));   

      
        
        if(!! this.addUserQuery.AccessApi)
        {
          let mustChked = this.addUserQuery.AccessApi.split(' / ');
          //console.log(mustChked);
          this.checkList =mustChked; 
        }  
        else
        {
          this.checkList = [];
        }
        this.setAccount = true;

    },

    async deleteUser(val) {
        //console.log(val);
        let deleteId = val.UserID;
        let qry = { UserID: deleteId};
        //console.log(qry);
        await this.$api.apimgrs.deleteUser(qry).then(res=>{
            console.log(res);
            this.$notify({
              title: "成功",
              message: "刪除使用者成功",
              type: "success",
            });
            this.setAccount= false;
        }).catch(err=>{
            this.$notify({
              title: "發生錯誤",
              message: "刪除使用者發生錯誤",
              type: "error",
            });
        });
        await this.getList();

    },

    async editUseApi() {
      if( this.checkList.length==0) {
            this.$notify({
              title: "請選擇授權Api",
              message: "尚未勾選Api",
              type: "warning",
            });
      };

      // this.addUserQuery  表示使用者選了那一個.....
      console.log("chkList",this.checkList );

      let piNo = this.checkList.map(y=>{
        return this.allApis.filter(x=>x.Name==y)[0].ID;
      })

      let apiNos = piNo.join(',');
      console.log("newApi",apiNos);
      console.log(this.addUserQuery);

      let qry = { UserID:this.addUserQuery.UserID, AccessApi : apiNos };
      console.log(qry);
          await this.$api.apimgrs.updateUser(qry).then(res=>{
              //console.log(res);
              this.$notify({
                title: "成功",
                message: "編輯Api授權成功",
                type: "success",
              });
              this.setAccount= false;
          }).catch(err=>{
              this.$notify({
                title: "發生錯誤",
                message: "編輯Api授權發生錯誤",
                type: "error",
              });
          });
      await this.getList();
    },

    async addUserAccount() {
        
        let ut = this.$refs.userTree.getCheckedKeys();
        if( ut.length==0)
        {
            this.$notify({
              title: "請選擇授權Api",
              message: "尚未勾選Api",
              type: "warning",
            });
        }
        let accessApi = ut.join(',');
        let qry = this.addUserQuery;
        qry.AccessApi = accessApi;
        // 20221026, 新增User時, 不要有UserID, 
        //delete qry.UserID;
        console.log(qry);
        if( this.userMode=="add") {
          await this.$api.apimgrs.addUser(qry).then(res=>{
              console.log(res);
              this.$notify({
                title: "成功",
                message: "新增使用者成功",
                type: "success",
              });
              this.setAccount= false;
          }).catch(err=>{
              this.$notify({
                title: "發生錯誤",
                message: "新增使用者發生錯誤",
                type: "error",
              });
          });
        }
        else { // 編輯
          await this.$api.apimgrs.updateUser(qry).then(res=>{
              //console.log(res);
              this.$notify({
                title: "成功",
                message: "編輯使用者成功",
                type: "success",
              });
              this.setAccount= false;
          }).catch(err=>{
              this.$notify({
                title: "發生錯誤",
                message: "編輯使用者發生錯誤",
                type: "error",
              });
          });
        }


        await this.getList();
        //console.log(accessApi);
    },

    async handleSearch() {
        //this.userQuery.Role="user";        
        await this.getList();
    },

    async pageChange(val) {
      this.$store.dispatch("loadingHandler", true);
      this.Page_Num = val;
      await this.getList();
      this.$store.dispatch("loadingHandler", false);
    },    

    async getDept() {
      
      let cn = this.$store.state.userInfo.ComName;
      // console.log("userInfo=",this.$store.state.userInfo);
      // console.log("cn=",cn);
      if( !! cn) {
        //console.log(this.$store.state.userInfo);

        // deptList
        let req = cn;
        await this.$api.apimgrs.getDeptName(req).then((res)=>{
          //console.log(res);
          this.deptList = res[0].department;
          //console.log(this.deptList);
          //this.totalLists = res.total;
        });
      }
      

    },
 
    async getList() {
      this.$store.dispatch("loadingHandler", true);
      this.tableData = [];
      let req = {Page_Size: this.Page_Size, Page_Num: this.Page_Num,Role:"user"}; 
        if(this.userQuery.UserName.length>0) {            
            req.UserName = this.userQuery.UserName;
        }        
        if(this.userQuery.DeptName!=="all") {            
            let cn = this.$store.state.userInfo.ComName;
            if( !! cn) {
              req.ComName = cn;
              req.DeptName= this.userQuery.DeptName;
            }            
        }    
      //console.log(req);
      await this.$api.apimgrs.getAllUser(req).then((res)=>{
        this.tableData = res.data;
        this.totalLists = res.total;
      });

      //console.log(this.allApis);

      
      // 加工Api名稱, accessApiName   從allApis 中去查名稱


      this.tableData.forEach(x=>{
        if( !! x.AccessApi ) {

          x.apiIds = x.AccessApi;
          let choiceApi = x.AccessApi.split(',');
          //console.log(choiceApi);
          if( !! choiceApi) {
            let apiNames = [];
            choiceApi.forEach(y=>{
              //console.log(y);
              const nm = this.allApis.filter(z=>z.ID==y)[0];
              //console.log(nm);
              apiNames.push(nm.Name);
            });
            
            let mapData = choiceApi.map( q=>{
              return this.allApis.filter(z=>z.ID==q)[0].Name;
            });
            //x.AccessApiName = mapData.join(' / ');
            
            x.AccessApi = apiNames.join(' / ');
            
          }
          //console.log(x.AccessApiName);
        }
      });


      console.log(this.tableData);

      // setTimeout(() => {

      // }, 1000 )

      this.$forceUpdate();
      //console.log(this.tableData);
      this.$store.dispatch("loadingHandler", false);
    },





  },
  async mounted() {
    this.$store.dispatch("loadingHandler", true);
    await this.getTreeData();
    await this.getDept();
    await this.getList();  
    
    //this.$store.loading = true;
    let userID = window.localStorage.getItem("userID")
    this.$store.dispatch("loadingHandler", false);
  },

};
</script>

<style lang="scss" scoped>
// * {
//     outline: red solid 1px;
// }



.authBox {
  width: 100%;
  height: 100vh;
  background: linear-gradient(-135deg, #00abb9 20%, #fff 50%, transparent 50%)
    center center / 100% 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &--breadcrumb {
    padding: 20px 40px;
  }

  &--searchBox {
    padding: 20px 40px;
    display: flex;
    flex-direction: row;
    width: 100%;
    

    .itemWrap{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
        label{
            width: 100px;
            display: block;
            text-align: right;
            margin-right: 10px;
        }
    }
  }

  &--addUserBox {
    padding: 3px 40px;
    display: flex;
    flex-direction: row;
    justify-content: right;
  }
  &--listBox {
    padding: 20px 40px;
  }




}


  .popBox {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;

        &--popLeftBox {
            width:70%;
        }
        &--popRightBox {
            width:90%;
            border: solid 1px gray;
            box-shadow: 2px 2px 3px grey;
            margin-bottom: 20px;
            .popRightBox--footer {
                padding: 14px 20px;
                display: flex;
                // border-bottom: 1px solid grey;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                background-color: lightcyan;

                span {
                    font-size: 1.0rem;          
                }
            }
            .popRightBox--content {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                border-top: gray solid 1px;                        

                padding: 0px 20px;                
                overflow-y: auto;                
                .el-checkbox {
                  min-width:200px;
                }

                div {
                  margin: 10px 0px;
                  font-size: 1.2rem;
                }
            }
        }   

    }





</style>